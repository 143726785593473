import { gsap } from "gsap";
import Player from '@vimeo/player';
    
import { ScrollTrigger } from "gsap/ScrollTrigger";

/* The following plugins are a Club GSAP perk */
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { SplitText } from "gsap/SplitText";

import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';




gsap.registerPlugin(ScrollTrigger,ScrollSmoother,SplitText);


// if (ScrollTrigger.isTouch !== 1) {
    // only create if it's not a touch-only device...
    let smoother = ScrollSmoother.create({
        smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        smoothTouch: 0.3, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
    });
// }



// mobile nav functionality
let mobileNav = document.getElementById("mobile-nav")
let mobileNavContent = document.getElementById("mobile-nav-content")
let mobileNavItems = document.querySelectorAll(".mobile-nav-item")
let mobileNavItemWrappers = document.querySelectorAll(".mobile-nav-item-wrapper")
let mobileNavItemArrows = document.querySelectorAll(".mobile-nav-item-arrow")
let navBtn = document.getElementById("mobile-nav-btn")
let navEx = document.getElementById("nav-menu-exit-btn")

const mobileNavOpenTl = gsap.timeline()
const mobileNavCloseTl = gsap.timeline()

gsap.set(mobileNav, {y: '100%'})

mobileNavOpenTl
.fromTo(mobileNav, {y: '100%'}, {y: '0%', ease: "power3.out", duration: 1})

mobileNavCloseTl
.fromTo(mobileNav, {y: '0%'},{y: '100%', ease: "power3.out", duration: 1})

mobileNavItems.forEach((item, i) => {


    item.split = new SplitText(item, { 
        type: "words"
    });
    if(i === 0){
        mobileNavOpenTl
        .from(item.split.words, {
           
            y: 100,
            duration: 1,
            ease: "expo"
        }, "<+=.7")
    } else {
        mobileNavOpenTl
        .from(item.split.words, {
        
            y: 100,
            duration: 1,
            ease: "expo"
        }, "<+=.1")
    }

    if(!isTouchDevice()) {
        item.addEventListener("mouseover", () => {
            mobileNavItemArrows[i].classList.remove('hidden')
         })
         item.addEventListener("mouseout", () => {
             mobileNavItemArrows[i].classList.add('hidden')
         })
    }
    
    
})


// banner headline split text animation
let bannerHeadline = document.querySelectorAll(".banner-text-container h1")

if(bannerHeadline.length){

    const bannerAni = gsap.timeline()

    bannerHeadline.forEach(headline => {
        
        headline.split = new SplitText(headline, { 
            type: "lines"
        });
        bannerAni
        .to(headline, {opacity: 1})
        .from(headline.split.lines, {
        
            y: 100,
            opacity: 0,
            duration: 2,
            delay: .5,
            ease: "expo",
            stagger: {
                from: "start", //try "center" and "edges"
                each: 0.3
            }
        }, "<")
    })

    if (document.readyState === 'complete') {
        // The page is fully loaded
        bannerAni.play()
    }
}




if(navEx){
    navEx.addEventListener("click", function() {
        if(mobileNav.classList.contains("mobile-nav-active")) {
            
            mobileNavCloseTl.restart();
            setTimeout(() => {
                mobileNav.classList.remove("mobile-nav-active")
            },900)

        }   
    })
}
if(navBtn){
    navBtn.addEventListener("click", function() {
        if(!mobileNav.classList.contains("mobile-nav-active")) {
            mobileNav.classList.add("mobile-nav-active")
            mobileNavOpenTl.restart();
        }
    })
} 


// custom mouse movement
// const cursor = document.querySelector('.cursor');
const cursorinner = document.querySelector('.cursor2');
const cursorinnerText = document.querySelector('.cursor2 > span');
const achorTags = document.querySelectorAll("a")
const buttonTags = document.querySelectorAll("button")
const inputTags = document.querySelectorAll("input")
const textAreaTags = document.querySelectorAll("textarea")
const selectTags = document.querySelectorAll("select")
const slides = document.querySelectorAll(".swiper-slide")
const whiteSections = document.querySelectorAll("section.bg-white")

function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }

//   console.log(isTouchDevice())

if(cursorinner && !isTouchDevice()) { 
  
    //   gsap.to(cursor, {opacity:0})
    gsap.to(cursorinner, {opacity:0})
    let cursorTimout 
    document.addEventListener('pointermove', function(e){
     
        //   gsap.to(cursor, {opacity:.5})
        gsap.to(cursorinner, {opacity:1})
        clearTimeout(cursorTimout);

        var x = e.clientX;
        var y = e.clientY;
        // cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
        cursorinner.style.left = x + 'px';
        cursorinner.style.top = y + 'px';

        cursorTimout = setTimeout(() => {
            // gsap.to(cursor, {opacity:0})
                gsap.to(cursorinner, {opacity:0})
            },
        1000
        );
    });

    // anchor tag change
    for (let i = 0; i < achorTags.length; i++) {
        const anchor = achorTags[i];
        
        anchor.addEventListener("mouseover", () => {
            cursorinner.classList.add("cursor2-hover")
        })
        anchor.addEventListener("mouseout", () => {
            cursorinner.classList.remove("cursor2-hover")
        })
        
       
    }
    // button tags change 
    for (let i = 0; i < buttonTags.length; i++) {
        const button = buttonTags[i];
        button.addEventListener("mouseover", () => {
            cursorinner.classList.add("cursor2-hover")
        })
        button.addEventListener("mouseout", () => {
            cursorinner.classList.remove("cursor2-hover")
        })
    }
    // input tags change 
    for (let i = 0; i < inputTags.length; i++) {
        const input = inputTags[i];
        input.addEventListener("mouseover", () => {
            cursorinner.classList.add("cursor2-hover")
        })
        input.addEventListener("mouseout", () => {
            cursorinner.classList.remove("cursor2-hover")
        })
    }
    // textArea tags change 
    for (let i = 0; i < textAreaTags.length; i++) {
        const textArea = textAreaTags[i];
        textArea.addEventListener("mouseover", () => {
            cursorinner.classList.add("cursor2-hover")
        })
        textArea.addEventListener("mouseout", () => {
            cursorinner.classList.remove("cursor2-hover")
        })
    }
    // select tags change 
    for (let i = 0; i < selectTags.length; i++) {
        const select = selectTags[i];
        select.addEventListener("mouseover", () => {
            cursorinner.classList.add("cursor2-hover")
        })
        select.addEventListener("mouseout", () => {
            cursorinner.classList.remove("cursor2-hover")
        })
    }
    
} 

// Reel Animation
const reelWrapper = document.getElementById("reel-wrapper")
const reelContainer = document.getElementById("reel-container")
const reelContainerInner = document.getElementById("reel-container-inner")
const reel = document.getElementById("reel")
const reelIframe = document.querySelector("#reel iframe")
const reelPlayer = new Player(reelIframe);
const reelBtns = document.querySelectorAll(".reel-button")
const reelCloseBtn = document.getElementById("reel-close-button")
const smoothContent = document.getElementById("smooth-content")
 
if(reelWrapper && reelContainer && reel && reelBtns.length && smoothContent) {

    reelPlayer.pause()

    let tlIn = gsap.timeline({
        paused: true
    })

    tlIn
    .to(reelWrapper, {zIndex: 30, duration: .01})
    .to(reelWrapper, {opacity: 1}, "<+=.1")
    .to(reelContainer, {width: "80%", height: "80%", ease: "power1.in"})
    .to(reelContainerInner, {borderRadius: "50px", ease: "power1.in"}, "<")
    .to(reel, {opacity: 1}, "<+=.3")

    let tlOut = gsap.timeline({
        paused: true
    })

    tlOut
    .to(reel, {opacity: 0})
    .to(reelContainer, {width: "100%", height: "100%", ease: "power1.out"}, "<+=.3") 
    .to(reelContainerInner, {borderRadius: "0px", ease: "power1.in"}, "<")
    .to(reelWrapper, {opacity: 0}, "<+=1")
    .to(reelWrapper, {zIndex: -20}, "<+=.1")

    for (let i = 0; i < reelBtns.length; i++) {
        const element = reelBtns[i];
        element.addEventListener("click", () => {
            tlIn.restart();
            reelPlayer.play()
        })
        
    }
    
    reelCloseBtn.addEventListener("click", () => {
        tlOut.restart();
        reelPlayer.pause()
    })
}

// Icon Logo
const logoContainer = document.getElementById("logo-container")
const logo = document.getElementById("icon-logo")

if(logo && logoContainer) {
    logoContainer.addEventListener("mouseover", () => {
        logo.play(); 
    })
}


// Scrolltrigger pins
const pinSections = document.querySelectorAll(".pin-section")

if(pinSections.length){
    for (let i = 0; i < pinSections.length; i++) {

        const el = pinSections[i];

        const elHeight = el.innerHeight

        let st = ScrollTrigger.create({
            trigger: el,
            start: () => el.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
            pin: true, 
            pinSpacing: false 
          });
        
    }
}

// Large Text Push Animations
const lgTextPushContainers = document.querySelectorAll(".large-text-push-container")
const lgTextPushBtns = document.querySelectorAll(".large-text-push-btn")
const pushLinesOne = document.querySelectorAll(".push-line-one")
const pushLinesTwo = document.querySelectorAll(".push-line-two")

if(lgTextPushContainers.length){
    for (let i = 0; i < lgTextPushContainers.length; i++) {
        const el = lgTextPushContainers[i];
        const elBtn = lgTextPushBtns[i]
        const lineOne = pushLinesOne[i]
        const lineTwo = pushLinesTwo[i]
        
        el.addEventListener("mouseover", () => {
            elBtn.classList.remove("opacity-0")
            gsap.to(lineOne, {x: -50})
            gsap.to(lineTwo, {x: 50})
        })
        el.addEventListener("mouseout", () => {
            elBtn.classList.add("opacity-0")
            gsap.to(lineOne, {x: 0})
            gsap.to(lineTwo, {x: 0})
        })

    }
}

// Large Text Pull Animations
const lgTextPullContainers = document.querySelectorAll(".large-text-pull-container")
const pullLinesOne = document.querySelectorAll(".pull-line-one")
const pullLinesTwo = document.querySelectorAll(".pull-line-two")

if(lgTextPullContainers.length){
    
    for (let i = 0; i < lgTextPullContainers.length; i++) {
        const el = lgTextPullContainers[i];
        const lineOne = pullLinesOne[i]
        const lineTwo = pullLinesTwo[i]

        gsap.set(lineOne, {x: "-100%"})
        gsap.set(lineTwo, {x: "100%"})
        
        let tl = gsap.timeline({
            // yes, we can add it to an entire timeline!
            scrollTrigger: {
                trigger: el,
                start: "top bottom",
                scrub: true
            }
          })

        tl
        .to(lineOne, {x: -50 })
        .to(lineTwo, {x: 50 }, "<")

    }
}


// Ticker ScrollTrigger
// const tickerContainers = document.querySelectorAll(".ticker-container")
// const tickers = document.querySelectorAll(".ticker")

// if(tickerContainers.length) {
//     for (let i = 0; i < tickerContainers.length; i++) {
//         const elContainer = tickerContainers[i];
//         const el = tickers[i]
//         const elWidth = elContainer.offsetWidth

//         // console.log(elWidth)
          
//           let tl = gsap.timeline({
//             // yes, we can add it to an entire timeline!
//             scrollTrigger: {
//                 trigger: elContainer,
//                 start: "top bottom",
//                 end: "bottom top",
//                 scrub: true
//             }
//           })

//         tl
//         .to(el, {x: -elWidth-1000 })
//     }
// }
function Marquee(selector, speed) {
    const parentSelector = document.querySelector(selector);
    if(parentSelector) {
        const clone = parentSelector.innerHTML;
        const firstElement = parentSelector.children[0];
        let i = 0;
        parentSelector.insertAdjacentHTML('beforeend', clone);
        parentSelector.insertAdjacentHTML('beforeend', clone);
    
        setInterval(function () {
        firstElement.style.marginLeft = `-${i}px`;
        if (i > firstElement.clientWidth) {
            i = 0;
        }
        i = i + speed;
        }, 0);
    }
  }
  
  //after window is completed load
  //1 class selector for marquee
  //2 marquee speed 0.2
  window.addEventListener('load',Marquee('.marquee', 0.2))

// Color Block Pin Section
const cbpSections = document.querySelectorAll(".color-block-pin-section")
const cbpSectionWrappers = document.querySelectorAll(".color-block-pin-section-wrapper")
const cbpTexts = document.querySelectorAll(".color-block-pin-text")
const pins = document.querySelectorAll(".color-block-pin")

if( cbpSections.length ){
    for (let i = 0; i < cbpSections.length; i++) {
        const elSection = cbpSections[i];
        const elSectionWrapper = cbpSectionWrappers[i];
        const elText = cbpTexts[i]

        // let st = ScrollTrigger.create({
        //     trigger: elSection,
        //     start: () => elSection.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
        //     pin: true, 
        //     pinSpacing: false 
        // });

        const pinSectionTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: elSection,
                start: "top top",
                end: "+=200%",
                scrub: true,
                pin: elSectionWrapper
            },
        })

        pinSectionTimeline
        .to(pins, {y: -window.innerHeight - 400, stagger: .2})

        // for (let i = 0; i < pins.length; i++) {
        //     const el = pins[i];
            
        // }
        
    }
}


// Arrow points
const arrowPoints = document.querySelectorAll(".arrow-point")

if(arrowPoints.length){
    for (let i = 0; i < arrowPoints.length; i++) {
        const el = arrowPoints[i];
        
        el.innerHTML = '<svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.1555 13.2972L22.6002 7.56779H0.195312V6.75112H22.5772L16.1555 1.04512L16.7475 0.450745L24.0633 6.95439L24.3278 7.18501L23.9936 7.44812L16.7475 13.8907L16.1555 13.2972Z" fill="currentColor"/></svg>' + el.innerHTML
    }
}


// Steps hover functionality
const steps = document.querySelectorAll(".step")
const stepImgContainer = document.querySelectorAll(".step-img-container")
const stepTextBoxes = document.querySelectorAll(".step-text-box")

if(steps.length){
    for (let i = 0; i < steps.length; i++) {
        const el = steps[i];
        const elImgContainer = stepImgContainer[i];
        const elTextBox = stepTextBoxes[i];
        
        el.addEventListener("mouseover", () => {
            elImgContainer.classList.add("opacity-0")

            // elTextBox.classList.remove("text-black")
            // elTextBox.classList.add("text-white")
            elTextBox.classList.remove("opacity-0")
        })
        el.addEventListener("mouseout", () => {
            elImgContainer.classList.remove("opacity-0")

            // elTextBox.classList.remove("text-white")
            // elTextBox.classList.add("text-black")
            elTextBox.classList.add("opacity-0")
        })
    }
}


// Work filters container pinning
let workFiltersContainer = document.getElementById("work-filters-container")
const categoryFilterLargeSection = document.getElementById("category-filter-large-section")

// if(workFiltersContainer){
//     let categorySectionHeight = categoryFilterLargeSection.innerHeight
//     ScrollTrigger.create({
//         trigger: workFiltersContainer,
//         start: "top top+=50px",
//         // the nect line (with the arrow function) is 'a functional value' () =>
//         end: () => `${categoryFilterLargeSection.offsetHeight - workFiltersContainer.offsetHeight}px 20%`,
//         // this line ensures the functional value gets recalculated on resize
//         invalidateOnRefresh: true,
//         pin: workFiltersContainer,
//         // pinSpacing: true,
       
//       });
// }



// Image Grid One Swiper 
const imageGridOneSwiper = new Swiper('.image-grid-one-swiper', {
    // Optional parameters
    modules: [Navigation],
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    spaceBetween: 100,
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-grid-one-button-next',
        prevEl: '.swiper-grid-one-button-prev',
    },

});

// Image Grid Two Swiper 
const imageGridTwoSwiper = new Swiper('.image-grid-two-swiper', {
    // Optional parameters
    modules: [Navigation],
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    spaceBetween: 100,
    breakpoints: {
        // when window width is >= 640px
        768: {
            slidesPerView: 2
        }

      },
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-grid-two-button-next',
        prevEl: '.swiper-grid-two-button-prev',
    },

});

// Image Grid Three Swiper 
const imageGridThreeSwiper = new Swiper('.image-grid-three-swiper', {
    // Optional parameters
    modules: [Navigation],
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    spaceBetween: 100,
    breakpoints: {
        // when window width is >= 640px
        768: {
            slidesPerView: 2
        },
        1024: {
            slidesPerView: 3
        }

      },
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-grid-three-button-next',
        prevEl: '.swiper-grid-three-button-prev',
    },

});

// Image Grid Four Swiper 
const imageGridFourSwiper = new Swiper('.image-grid-four-swiper', {
    // Optional parameters
    modules: [Navigation],
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    spaceBetween: 100,
    breakpoints: {
        // when window width is >= 640px
        768: {
            slidesPerView: 2
        },
        1024: {
            slidesPerView: 3
        },
        1280: {
            slidesPerView: 4
        }

      },
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-grid-four-button-next',
        prevEl: '.swiper-grid-four-button-prev',
    },

});

// Sprig Swiper
const sprigSwiper = new Swiper('.sprig-swiper', {
    // Optional parameters
    modules: [Navigation],
    direction: 'horizontal',
    loop: false,
    slidesPerView: 1,
    spaceBetween: 100,
    // Navigation arrows
    navigation: {
        nextEl: '.sprig-swiper-button-next',
        prevEl: '.sprig-swiper-button-prev',
    },

});

const yearTags = document.querySelectorAll(".years")

if(yearTags.length){

    sprigSwiper.on('slideChange', (e) => {
        
        for (let i = 0; i < yearTags.length; i++) {
            let el = yearTags[i]
            if(i === sprigSwiper.activeIndex){
                el.classList.remove("hidden")
            } else {
                el.classList.add("hidden")
            }
        }
    })
  
}



// Scrollback Nav
const showAnim = gsap.from('#header', { 
    yPercent: -100,
    paused: true,
    duration: 0.5
  }).progress(1);
  
  ScrollTrigger.create({
    start: "top top",
    end: "max",
    onUpdate: (self) => {
      self.direction === -1 ? showAnim.play() : showAnim.reverse()
    }
  });

  // contact form select onChange
//   const contactSelect = document.getElementById("contact-form-select")
//   const contactFormText = document.querySelector(".replace-content") 

//   if(contactSelect && contactFormText){
//     contactSelect.addEventListener("change", () => {
//         console.log(contactFormText.innerHTML)
//         // contactFormText.innerHTML = contactFormText.innerHTML.split('<em class="font-italic">')[0]
//         contactFormText.innerHTML = contactSelect.value ? contactSelect.value : 'Design'
//     })
//   }

// contact form text auto rotate
  
  const contactFormText = document.querySelector(".replace-content") 

  if(contactFormText){
    const textArray = ['Design', 'People', 'Brands', 'Place', 'Products']
    let count = 0

    const changeText = () => {
        contactFormText.innerHTML = textArray[count]
        if (count == textArray.length - 1) {
            count = 0
        } else {
            count++ 
        }
    }

    setInterval(changeText, 500)
    changeText()
  }

  // Team member circles fade in
  const teamMembers = document.querySelectorAll(".random-member-circle-container")

  if(teamMembers.length){
    let teamCirclesTl = gsap.timeline()

    for (let i = 0; i < teamMembers.length; i++) {
       
        const el = teamMembers[i];
        teamCirclesTl
        .to(el, {opacity: 1})
    }
  }